import React from "react";
import Layout from "../components/Layout";
import Nav from "../components/Nav";
import Graveyard from "../components/Graveyard";

const Stake = () => (
  <Layout pageTitle="AVAXGHOST Stake">
    <Nav />
    <Graveyard />
  </Layout>
);

export default Stake;