import React,{Component} from "react";
import ReactTooltip from 'react-tooltip';
import {isConnected, connect} from '../assets/scripts/wallet.js';
import * as contract from '../assets/scripts/contract.js';
import { log } from '../assets/scripts/logger.js';
import ghostIcon from "../assets/images/resources/mini-ghost.png";
import spiritIcon from "../assets/images/resources/sprt.png";
import ghostPurple from "../assets/images/resources/ghost-purple.png";
import ghostGreen from "../assets/images/resources/ghost-green.png";

export default class Graveyard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isWalletConnected: undefined,
      isContractInitialized: undefined,
      isGraveyardConstructed: undefined,
      isPageReadyToDisplay: false,
      team: 0,
      graveyardLevel: 1,
      ghostBalance: 0,
      harvestAmount: 0,
      spiritBalance: 0,
      levelUpCost: 0,
      maxGraveyardLevel: 13,
      baseHarvestingAmount: 0.0001,
      bonusHarvestingAmount: 0,
      graveyardLevelHarvestingMultiplier: 1,
      teamHarvestingBonusMultiplier: 1,
      changeSideCostEther: 4,
      changeSideCostSpirit: 4000,
      changeSideEnabled: false,
    }
  }

  connect = (e) => {
    e.preventDefault();
    if(!isConnected()) {
      connect();
    }
  };

  chooseTeam = async (e, team) => {

    e.preventDefault();

    if(this.state.graveyardConstructed) {
      return;
    }

    await contract.constructGraveyard(team);

    await contract.queryGameStats();

    this.reloadData();

  };

  harvest = async (e) => {

    e.preventDefault();

    await contract.harvest();

  };

  levelUp = async (e) => {

    e.preventDefault();

    await contract.levelUp();

  };

  changeSide = async (e) => {

    e.preventDefault();

    if(this.state.team === 0) {
      return;
    }

    await contract.changeSide(this.state.team === 1 ? 2 : 1);

  };

  componentDidMount() {

    this.setState({isWalletConnected: isConnected()});

    document.addEventListener('onAccountUpdate', () => {
      log("[GAME]: Event onAccountUpdate raised!");
      this.setState({isWalletConnected: isConnected()});
      this.refreshData();
    });

    document.addEventListener('onContractInitialized', () => {
      log("[GAME]: Event onContractInitialized raised!");
      this.setState({isContractInitialized: true});
      this.reloadData();
    });

    document.addEventListener('onGameStatsFetched', () => {
      log("[GAME]: Event onGameStatsFetched raised!");
      this.reloadData();
    });

    document.addEventListener('onGraveyardConstructed', () => {
      log("[GAME]: Event onGraveyardConstructed raised!");
      this.refreshData();
    });

    document.addEventListener('onHarvestSucceed', () => {
      log("[GAME]: Event onHarvestSucceed raised!");
      this.refreshData();
    });

    document.addEventListener('onLevelUpSucceed', () => {
      if(process.env.NODE_ENV === "development") {
        log("[GAME]: Event onLevelUpSucceed raised!");
      }
      this.refreshData();
    });

    document.addEventListener('onChangeSideSucceed', () => {
      log("[GAME]: Event onChangeSideSucceed raised!");
      this.refreshData();
    });

    this.refreshData();

  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId); 
  }

  reloadData = () => {

    const team = contract.team;

    const graveyardConstructed = team === 1 || team === 2;

    this.setState({
      isContractInitialized: contract.isContractInitialized,
      isGraveyardConstructed: graveyardConstructed,
      team: team,
      graveyardLevel: Math.max(0, contract.graveyardLevel),
      ghostBalance: Math.max(0, contract.balance),
      harvestAmount: Math.max(0, contract.harvestAmount),
      spiritBalance: Math.max(0, contract.spiritBalance),
      levelUpCost: Math.max(0, contract.graveyardLevelUpCost),
      maxGraveyardLevel: Math.max(0, contract.graveyardMaxLevel),
      baseHarvestingAmount: Math.max(0, contract.harvestingBaseAmount),
      bonusHarvestingAmount: Math.max(0, contract.harvestingBonusAmount),
      graveyardLevelHarvestingMultiplier: Math.max(0, contract.harvestingLevelMultiplier),
      teamHarvestingBonusMultiplier: Math.max(0, contract.harvestingTeamMultiplier),
      changeSideCostEther: Math.max(0, contract.changeSideCostEther),
      changeSideCostSpirit: Math.max(0, contract.changeSideCostSpirit),
      changeSideEnabled: contract.changeSideEnabled
    });

    this.recalculatePageReady();

    this.setupHarvestPoller();

  };

  recalculatePageReady = () => {

    const pageReady = this.state.isWalletConnected !== undefined 
    && this.state.isContractInitialized !== undefined
    && this.state.isGraveyardConstructed !== undefined;

    this.setState({isPageReadyToDisplay: pageReady});

  }

  refreshData = async () => {
    contract.queryGameStats();
  };

  setupHarvestPoller = () => {

    if(this.state.intervalId) {
      clearInterval(this.state.intervalId); 
    }

    const intervalId = setInterval(() => this.pollHarvestAmount(), 16000);
    this.setState({ intervalId })

  };

  pollHarvestAmount = async () => {

    if(!isConnected()) {
      return;
    }

    log("Harvest amount is currently polling!");
    
    await contract.queryHarvestAmount();

    this.setState({
      harvestAmount: parseFloat(contract.harvestAmount ?? 0)
    });

  };

  teamName = () => {
    switch(this.state.team) {
      case 1: return "Purple";
      case 2: return "Green";
      default: return "";
    }
  };

  graveyardBgResource = () => {
    
    const level = this.state.graveyardLevel;

    if(!level) {
      return 1;
    }

    return Math.max(1, level);

  };

  render() {
    return (
      <section className="banner-graveyard" id="graveyard" style={{backgroundImage: "url(" + require("../assets/images/background/graveyard-" + this.graveyardBgResource() + ".png") + ")"}}>
        <div className="graveyard-overlay"></div>
        <div className="graveyard-container">
          <div className="graveyard-content">
            <h1 className="banner-one__title">
              GRAVEYARD
            </h1>
            {this.state.isPageReadyToDisplay
            ?
            (
              <div className="animated-content">
                {this.state.isWalletConnected
                ?
                (
                <div>
                  {this.state.ghostBalance > 0
                  ?
                  (
                  <div>
                    <div>
                      {this.state.isGraveyardConstructed
                      ?
                      (
                      <div>
                        <span className={"graveyard-level " + (this.teamName().toLowerCase())}>
                          LEVEL {this.state.graveyardLevel <= 0 ? 1 : this.state.graveyardLevel}
                        </span>
                        <div className="graveyard-bottom">
                          <div className={"graveyard-bottom-container " + (this.state.team == 1 ? "purple" : this.state.team == 2 ? "green" : "")}>
                            <div className="info-container">
                              <div className="ghosts">
                                <img src={ghostIcon} alt="" />
                                <span className="title">AVAXGHOST</span>
                                <br />
                                <span className="value">{this.state.ghostBalance}</span>
                              </div>
                              <div className="spirits collected">
                                <img src={spiritIcon} alt="" />
                                <span className="title">COLLECTED $SPRT</span>
                                <br />
                                <span className="value">{this.state.harvestAmount.toFixed(2)}</span>
                              </div>
                              <div className="spirits balance">
                                <img src={spiritIcon} alt="" />
                                <span className="title">TOTAL $SPRT</span>
                                <br />
                                <span className="value">{this.state.spiritBalance.toFixed(2)}</span>
                              </div>
                            </div>
                            <div className="stats-info-container">
                              {/* <div>
                                <span className="title">Team</span><span className={"value " + (this.state.team == 1 ? "purple" : this.state.team == 2 ? "green" : "")}>{this.teamName()}</span>
                              </div> */}
                              <div>
                                <span className="title">Estimated $SPRT/hr</span><span className="value">{(3600 * (this.state.baseHarvestingAmount + this.state.bonusHarvestingAmount) * this.state.graveyardLevel * this.state.teamHarvestingBonusMultiplier).toFixed(2)}</span>
                              </div>
                              <div>
                                <span className="title">Bonus Harvesting Multiplier</span><span data-tip data-for="bh-tooltip" className="bh-tooltip-trigger">&nbsp;?&nbsp;</span><span className="value">x&nbsp;{((this.state.baseHarvestingAmount + this.state.bonusHarvestingAmount) / this.state.baseHarvestingAmount).toFixed(1)}</span>
                              </div>
                              <ReactTooltip className="bonus-harvesting-tooltip" id="bh-tooltip" effect="solid">
                                <span className="title">
                                  Bonus Harvesting Multiplier
                                </span>
                                <br />
                                <span className="description">
                                  $SPRT coefficient that you earn based on the number of ghosts you have
                                </span>
                                <ul>
                                  <li>&nbsp;6&nbsp;&#x2212;&nbsp;10&nbsp;&#61;&nbsp;2x</li>
                                  <li>11&nbsp;&#x2212;&nbsp;15&nbsp;&#61;&nbsp;2.5x</li>
                                  <li>16&nbsp;&#x2212;&nbsp;20&nbsp;&#61;&nbsp;3x</li>
                                  <li>21&nbsp;&#x2212;&nbsp;30&nbsp;&#61;&nbsp;4x</li>
                                  <li>31&nbsp;&#x2212;&nbsp;40&nbsp;&#61;&nbsp;5x</li>
                                  <li>41&nbsp;&#x2212;&nbsp;50&nbsp;&#61;&nbsp;6x</li>
                                  <li>51&nbsp;&#x2212;&nbsp;60&nbsp;&#61;&nbsp;7x</li>
                                  <li>61&nbsp;&#x2212;&nbsp;70&nbsp;&#61;&nbsp;8x</li>
                                  <li>71&nbsp;&#x2212;&nbsp;80&nbsp;&#61;&nbsp;9x</li>
                                  <li>81&nbsp;&#x2212;&nbsp;90&nbsp;&#61;&nbsp;10x</li>
                                  <li>91&nbsp;&#43;&nbsp;=&nbsp;11x</li>
                                </ul>
                              </ReactTooltip>
                              <div>
                                <span className="title">Level Harvesting Multiplier</span><span className="value">x&nbsp;{this.state.graveyardLevelHarvestingMultiplier}</span>
                              </div>
                              <div>
                                <span className="title">Team Harvesting Multiplier</span><span className="value">x&nbsp;{this.state.teamHarvestingBonusMultiplier}</span>
                              </div>
                              {this.state.graveyardLevel === this.state.maxGraveyardLevel
                              ?
                              (
                                <div>
                                  <span className="title">Max Level Reached</span>
                                </div>
                              )
                              :
                              (
                                <div>
                                  <span className="title">Next Level Cost</span><span className="value">{this.state.levelUpCost}</span>
                                </div>
                              )
                              }
                              <div>
                                <span className="title">Change Side Cost ($AVAX)</span><span className="value">{this.state.changeSideCostEther}</span>
                              </div>
                              <div>
                                <span className="title">Change Side Cost ($SPRT)</span><span className="value">{this.state.changeSideCostSpirit}</span>
                              </div>
                            </div>
                          </div>
                          <div className="graveyard-actions">
                            <a href="#" className={"graveyard-btn" + (this.state.harvestAmount <= 0 ? " disabled" : "")} onClick={this.harvest}>
                              <span>Harvest</span>
                            </a>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <a href="#" className={"graveyard-btn" + (this.state.spiritBalance < this.state.levelUpCost || this.state.graveyardLevel === this.state.maxGraveyardLevel ? " disabled" : "")} onClick={this.levelUp}>
                              <span>Level Up</span>
                            </a>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <a href="#" className={"graveyard-btn" + (!this.state.changeSideEnabled ? " disabled" : "")} onClick={this.changeSide}>
                              <span>Change Side</span>
                            </a>
                          </div>
                        </div>
                      </div>
                      )
                      :
                      (
                      <div>
                        <span className="graveyard-choose-side">Choose Your Side!</span>
                        <br /><br />
                        <div className="graveyard-create-actions">
                          <div className="purple">
                            <a href="#" onClick={(e) => this.chooseTeam(e,1)}>
                              <img className="team" src={ghostPurple} alt="" height="240" />
                              <span className="graveyard-btn">PURPLE</span>
                            </a>
                          </div>
                          <div className="spacer"></div>
                          <div className="green">
                            <a href="#" onClick={(e) => this.chooseTeam(e, 2)}>
                              <img className="team" src={ghostGreen} alt="" height="240" />
                              <span className="graveyard-btn">GREEN</span>
                            </a>
                          </div>
                        </div>
                      </div>
                      )
                      }
                    </div>
                  </div>
                  )
                  :
                  <div>
                    <span className="graveyard-mint-first">You Need to Mint AVAXGHOST First!</span>
                    <br /><br />
                    <a href="/" className="banner-one__btn thm-btn mint-btn">
                      <span>MINT</span>
                    </a>
                  </div>
                  }
                </div>
                )
                :
                (
                <a href="#" className="banner-one__btn thm-btn mint-btn" onClick={this.connect}>
                  <span>CONNECT</span>
                </a>
                )
              }
              </div>  
            )
            :
            (
              <div className="loading">
                <div></div>
                <div></div>
              </div>  
            )
            }
          </div>
        </div>
      </section>
    )
  }

}